
import {
  computed,
  defineComponent,
  PropType,
  ref,
  useContext,
  onMounted,
  onUnmounted
} from '@nuxtjs/composition-api'
import DHorizontalScroll from '~/components/molecules/DS2/DHorizontalScroll'
import Gallery from '~/components/molecules/Gallery'
import { ServiceMovie } from '~/stub/apigateway/service/service_pb'

const ServiceDetailImagesSp = defineComponent({
  name: 'ServiceDetailImagesSp',
  components: {
    Vuetube: () => import('~/components/molecules/Vuetube'),
    Gallery,
    DHorizontalScroll,
    DLazyImage: () => import('~/components/atoms/DS2/DLazyImage')
  },
  props: {
    serviceName: {
      type: String,
      default: ''
    },
    serviceImageList: {
      type: Array as PropType<string[]>,
      default: () => []
    },
    serviceMovieList: {
      type: Array as PropType<ServiceMovie.AsObject[]>,
      default: () => []
    },
    moviePriorityFlag: {
      type: Boolean as PropType<boolean>,
      default: false
    }
  },
  setup(props) {
    const { $coconala, route } = useContext()

    /** ギャラリーコンポーネント */
    const gallery = ref<InstanceType<typeof Gallery>>()

    /** 活性状態のVuetubeリスト */
    const activeVuetubeList = ref<string[]>([])

    /** デバイスの幅と高さ */
    const deviceWidth = ref(0)
    const imageHeight = ref(0)

    const handleResize = () => {
      deviceWidth.value = document.body.clientWidth
      imageHeight.value = document.body.clientWidth * (19 / 23)
    }

    onMounted(() => {
      // 初期値設定
      handleResize()
      // リサイズイベントの登録
      window.addEventListener('resize', handleResize)
    })
    onUnmounted(() => {
      // クリーンアップ
      window.removeEventListener('resize', handleResize)
    })

    /** 動画アクティブ状態 */
    const isActiveVuetube = (key: string) => activeVuetubeList.value.includes(key)

    /** 動画アクティブ状態変更 */
    const changeActiveVuetube = (toBeActive: boolean, key: string) => {
      if (toBeActive) {
        if (!isActiveVuetube(key)) activeVuetubeList.value.push(key)
      } else {
        activeVuetubeList.value = activeVuetubeList.value.filter(v => v !== key)
      }
    }

    /** ギャラリーに表示する画像URLリスト */
    const galleryItemList = computed(() => {
      return props.serviceImageList.map(v => ({
        src: $coconala.serviceImageURLV2(v, 1220, 1240, 'resize')
      }))
    })

    const getServiceImageUrl = (image: string) => $coconala.serviceImageURLV2(image, 460, 380)

    /** ギャラリー表示処理 */
    const openGallery = (imageIndex: number) => {
      if (!gallery.value) return
      const option = {
        bgOpacity: 0.7,
        tapToClose: true
      }
      gallery.value.open(imageIndex, option)
    }

    return {
      gallery,
      isActiveVuetube,
      changeActiveVuetube,
      galleryItemList,
      deviceWidth,
      imageHeight,
      openGallery,
      getServiceImageUrl
    }
  }
})
export default ServiceDetailImagesSp
